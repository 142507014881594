import { render, staticRenderFns } from "./MoskvichFooter.vue?vue&type=template&id=059f4596&scoped=true&"
var script = {}
import style0 from "./MoskvichFooter.vue?vue&type=style&index=0&id=059f4596&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059f4596",
  null
  
)

export default component.exports