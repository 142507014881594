<template>
  <div class="moskvich-header-wrapper">
    <div class="header-height"></div>

    <div class="header header-height">
      <div class="logo">
        <a href="https://moskvich-auto.ru/">
          <img src="../assets/img/logo-moskvich-red.svg"/>
        </a>
      </div>

      <div class="burger-icon" @click="toggleBurger">
        <img v-if="isBurgerOpened" src="../assets/img/closeMenuIcon.svg"/>
        <img v-else src="../assets/img/burgerMenuIcon.svg"/>
      </div>

      <div class="fader" v-if="isBurgerOpened"></div>

      <div class="menus" :class="{ 'is-burger-opened': isBurgerOpened }">
        <div class="submenu">
          <a href="https://moskvich-auto.ru/news">НОВОСТИ</a>
          <a href="https://moskvich-auto.ru/contacts">КОНТАКТЫ</a>
          <a href="https://moskvich-auto.ru/brand">О БРЕНДЕ</a>
          <a href="https://moskvich-auto.ru/dealers">ДИЛЕРЫ</a>
        </div>

        <div class="main-menu">
          <a href="https://moskvich-auto.ru/models/moskvich-3">МОСКВИЧ 3</a>
          <a href="https://moskvich-auto.ru/models/moskvich-3e">МОСКВИЧ 3Е</a>
          <a href="https://configurator.moskvich-auto.ru/">КОНФИГУРАТОР</a>
          <a href="https://cars.moskvich-auto.ru/">АВТО В НАЛИЧИИ</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      isBurgerOpened: false,
    }
  },

  methods: {

    toggleBurger() {
      this.isBurgerOpened = !this.isBurgerOpened
    },

  },

}
</script>

<style lang="scss" scoped>
@import '../assets/scss/utils';

.moskvich-header-wrapper {
  @include print(&) {
    .header-height, .header {
      display: none;
    }
  }

  .header-height {
    height: 102px;

    @media screen and (max-width: 1023px) {
      height: 69px;
    }
  }

  .header {
    box-sizing: border-box;
    width: 100%;
    background: #fff;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    padding: 21px 74px;
  }

  .logo {
    float: left;

    width: 202px;
    height: 47px;

    margin-left: -2px;
    margin-top: 7px;
    border: none;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1023px) {
      float: none;
      position: absolute;
      left: 50%;
      margin-left: -100px;
      top: 0;
      margin-top: 8px;
    }
  }

  .menus {
    float: right;
    text-align: right;

    .submenu {
      font-size: 13px;
      line-height: 24px;

      a {
        color: gray;
        text-decoration: none;
        text-transform: uppercase;

        margin-left: 32px;

        &:hover {
          text-decoration: underline;
        }
      }
    }


    .main-menu {
      margin-top: 14px;

      font-size: 18px;
      font-weight: 400;
      line-height: 20px;

      a {
        color: #000;
        text-decoration: none;
        text-transform: uppercase;

        margin-left: 32px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      display: none;

      &.is-burger-opened {
        z-index: 150;

        box-sizing: border-box;
        display: flex;
        position: fixed;
        right: 0;
        top: 0;
        max-width: 100%;
        width: 320px;
        height: 100vh;

        background: #fff;
        box-shadow: 0 0 7px rgba(0, 0, 0, .2);

        padding: 108px 24px 10px 24px;
        flex-direction: column;

        text-align: left;

        .main-menu {
          order: 1;
        }

        .submenu {
          order: 2;
          border-top: solid 1px silver;
          padding-top: 14px;
        }

        a {
          font-size: 18px;

          display: block;
          color: #000;
          text-decoration: none;
          text-transform: uppercase;

          margin-left: 0;
          margin-bottom: 14px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .burger-icon {
    display: none;

    @media screen and (max-width: 1023px) {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 200;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .fader {
    @media screen and (max-width: 1023px) {
      display: block;
      background: rgba(0, 0, 0, .81);
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 125;
    }
  }
}
</style>
