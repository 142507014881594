import Vue from 'vue'
import MoskvichHeader from "@/components/MoskvichHeader.vue";
import MoskvichFooter from "@/components/MoskvichFooter.vue";

Vue.config.productionTip = false

function insertHeader(target: string | Element): void {
    new Vue({
        render: h => h(MoskvichHeader),
    }).$mount(target)
}

function insertFooter(target: string | Element): void {
    new Vue({
        render: h => h(MoskvichFooter)
    }).$mount(target)
}

const queue = window.MoskvichHF || []

declare global {
    interface Window {
        MoskvichHF: {
            push: Function,
            insertHeader: Function,
            insertFooter: Function,
        }
    }
}

window.MoskvichHF = {
    push: (callback: Function) => callback(),
    insertHeader,
    insertFooter,
}
